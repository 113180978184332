.social-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.social-links a {
  margin: 2rem;
  color: #fff;
  font-size: 2rem;
}

.social-links a:hover {
  transform: translateY(-5px);
}
