#hero {
  background: #fff;
  min-height: 100vh;
  height: 100vh;
  color: #000;
  z-index: -1;
  background: #fff;
  border-bottom: 0;
  align-items: center;
  margin-bottom: 0;
  padding: 0 5.6rem;
  font-weight: 400;
  display: flex;
  inset: 0;
}

#about {
  color: #fff;
  clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);
  height: 100vh;
  background-color: #937cc4;
  background-image: linear-gradient(135deg, #937cc4 0% 100%);
  border-top: 0;
  padding-bottom: 10%;
}

#projects {
  color: #000;
  background: #fff;
  margin-top: -10rem;
  padding-top: 15rem;
}

#contact {
  color: #fff;
  background: #937cc4;
  clip-path: polygon(0 15vh, 100% 0, 100% 100%, 0 100%);
  padding: 15rem 0 10rem 0;
  background-image: linear-gradient(135deg, #937cc4 0% 100%);
}

html {
  font-size: 62.5%;
}

body {
  text-align: center;
  scroll-behavior: smooth;
}

.App {
  font-family: "Source Code Pro", monospace;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.footer {
  color: #fff;
  background-color: #333;
  padding: 4.8rem 0;
}

.link {
  font-family: "montserrat", sans-serif;
  text-decoration: none;
  display: inline;
  padding: 20px 20px;
  cursor: pointer;
  font-size: 2em;
}

.link:hover {
  color: #937cc4;
}

.resume-link {
  font-family: "montserrat", sans-serif;
  text-decoration: none;
  display: inline;
  cursor: pointer;
  font-size: 1em;
  color: #000;
}

.resume-link:hover {
  color: #937cc4;
}

.navigation {
  text-align: center;
  padding-top: 20px;
}

p,
a {
  font-family: "Montserrat", sans-serif;
  font-size: 1.6rem;
}

.section {
  padding: 5rem 0;
}

.section-title {
  text-align: center;
  margin: 0 0 4.5rem;
  font-size: 4rem;
  font-weight: 700;
}

.text-color-main {
  color: #937cc4;
}

/* scroll to top button */
.top-btn-position {
  position: fixed;
  bottom: 15px;
  right: 20px;
}

.top-btn-style {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: #fff;
  color: #000;
  cursor: pointer;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1320px;
  }
}

@media (max-width: 75em) {
  #about,
  #contact {
    clip-path: none;
    height: 100%;
    margin-top: 0rem;
    padding: 8rem;
  }
  .hero .hero-title {
    font-size: 4rem;
  }
  #projects {
    padding-top: 8rem;
    padding-bottom: 3rem;
  }
}

@media (max-width: 56.25em) {
  .navigation {
    display: none;
  }
}

@media (max-width: 37.5em) {
  .section-title {
    font-size: 2.8rem;
    line-height: 1.5;
  }
  #contact .contact-wrapper__text {
    font-size: 2rem;
  }
}
