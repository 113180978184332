.custom-btn-contact {
  font-size: 2.4rem;
  display: inline-block;
  position: relative;
  padding: 0.8rem 1.6rem;
  font-weight: bold;
  line-height: 1;
  z-index: 1;
  transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.6s;
  color: #fff;
  border: 2px solid #fff;
}

.custom-btn-contact:before {
  right: 0;
  border: 1px solid #fff;
  border-left: 0;
  border-right: 0;
}

.custom-btn-contact:after {
  content: "";
  display: block;
  position: absolute;
  width: 0px;
  height: 100%;
  left: 0;
  bottom: 0;
  z-index: -1;
  transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.3s;
  background: #fff;
}

.custom-btn-contact:hover {
  color: #937cc4;
  text-decoration: none;
}

.custom-btn-contact:hover:after {
  width: 100%;
}

.contact-wrapper__text {
  text-align: center;
  font-size: 2.4rem;
  margin-bottom: 2.5rem;
}
